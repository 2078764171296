// Extend underscore.string.
(function(underscoreString) {

    if (underscoreString == null) return;

    underscoreString.pluralize = function(number, singular, plural) {
        if (number != null && singular == null) return;

        plural = (plural != null) ? plural : singular + 's';
        return (parseInt(number, 10) === 1) ? singular : plural;
    }

})(s);
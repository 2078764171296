/*
 * Takes an optional callback function and returns a promise. If the model or
 * collection is already synced, the callback runs and the promise is resolved
 * immediately. Otherwise, it waits until the first sync. Either way, the model
 * or collection object is passed into the callback method or promise resolve.
 *
 * collection.whenSyncd(function (collection) {
 * })
 *
 * collection.whenSyncd().then(function (collection) {
 * })
 */
(function (Backbone) {
    var whenSyncd = function (callback) {
        var _this = this;
        var deferred = $.Deferred();
        var func = function (obj) {
            try {
                if (typeof(callback) !== 'undefined') {
                    callback(obj);
                }
                deferred.resolve(_this);
            } catch (e) {
                deferred.reject(e);
            }
        }
        if (this.syncd || (typeof this.collection !== 'undefined' && this.collection.syncd)) {
            func(_this);
        } else {
            this.once('sync', function () { func(_this); });
        }
        return deferred;
    };

    Backbone.Model.prototype.whenSyncd = whenSyncd;
    Backbone.Collection.prototype.whenSyncd = whenSyncd;
})(Backbone);

var ENISOFormatParser = require('./parsers/EN/ENISOFormatParser').Parser;
var ENDeadlineFormatParser = require('./parsers/EN/ENDeadlineFormatParser').Parser;
var ENMonthNameLittleEndianParser = require('./parsers/EN/ENMonthNameLittleEndianParser').Parser;
var ENMonthNameMiddleEndianParser = require('./parsers/EN/ENMonthNameMiddleEndianParser').Parser;
var ENSlashDateFormatParser = require('./parsers/EN/ENSlashDateFormatParser').Parser;
var ENTimeAgoFormatParser = require('./parsers/EN/ENTimeAgoFormatParser').Parser;
var ENTimeExpessionParser = require('./parsers/EN/ENTimeExpressionParser').Parser;
var ENWeekdayParser = require('./parsers/EN/ENWeekdayParser').Parser;
var ENCasualDateParser = require('./parsers/EN/ENCasualDateParser').Parser;

var ENMergeDateTimeRefiner = require('./refiners/EN/ENMergeDateTimeRefiner').Refiner;
var ENMergeDateRangeRefiner = require('./refiners/EN/ENMergeDateRangeRefiner').Refiner;


var JPStandardParser = require('./parsers/JP/JPStandardParser').Parser;
var JPCasualDateParser = require('./parsers/JP/JPCasualDateParser').Parser;

var JPMergeDateRangeRefiner = require('./refiners/JP/JPMergeDateRangeRefiner').Refiner;


var OverlapRemovalRefiner = require('./refiners/OverlapRemovalRefiner').Refiner;
var ExtractTimezoneOffsetRefiner = require('./refiners/ExtractTimezoneOffsetRefiner').Refiner;
var ExtractTimezoneAbbrRefiner = require('./refiners/ExtractTimezoneAbbrRefiner').Refiner;
var UnlikelyFormatFilter = require('./refiners/UnlikelyFormatFilter').Refiner;


exports.strictOption = function () {
    return {

        parsers: [
        
            // EN
        	new ENISOFormatParser(),
            new ENDeadlineFormatParser(),
            new ENMonthNameLittleEndianParser(),
            new ENMonthNameMiddleEndianParser(),
            new ENSlashDateFormatParser(),
            new ENTimeAgoFormatParser(),           
            new ENTimeExpessionParser(),

            // JP
            new JPStandardParser(),
        ],

        refiners: [
            // Removing overlaping first
            new OverlapRemovalRefiner(),
            
            // ETC
            new ENMergeDateTimeRefiner(),
            new ENMergeDateRangeRefiner(),
            new JPMergeDateRangeRefiner(),

            // Extract additional info later
            new ExtractTimezoneOffsetRefiner(),
            new ExtractTimezoneAbbrRefiner(),
            new UnlikelyFormatFilter()
        ]
    }
};


exports.casualOption = function () {

    var options = exports.strictOption();
    // EN
    options.parsers.unshift(new ENCasualDateParser());
    options.parsers.unshift(new ENWeekdayParser());

    // JP
    options.parsers.unshift(new JPCasualDateParser());
    
    return options;
};
